<script setup>
import { onBeforeUnmount, onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Navbar from "@/components/PageLayout/Navbar.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonSwitch from "@/components/Core/ArgonSwitch.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import logo from "@/assets/logo.png";
import bgImg from "@/assets/img/bg-image.png";

const body = document.getElementsByTagName("body")[0];
const backgroundStyle = computed(() => {
  return {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover',
    alignItems: 'center'
  }
});
const store = useStore();
const router = useRouter();

const type = ref(false);

let email = "";
let password = "";
let accountId = "";

onBeforeMount(() => {
  store.state.app.hideConfigButton = true;
  store.state.app.showNavbar = false;
  store.state.app.showSidenav = false;
  store.state.app.showFooter = false;
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

const signIn = async () => {
  try {
    // Authenticate the user
    if(type.value) { 
      await store.dispatch("user/agentLogin", { accountId, email, password });
    }
    else {
      await store.dispatch("user/login", { email, password });
    }
    // Navigate to the home page after successful login
    router.push("/");
  } catch (error) {
    // Handle login error
    console.error("Login error:", error);
  }
};

</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <!-- Navbar component -->
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>

  <!-- Main content -->
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <!-- Sign in form -->
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signIn">
                    <div v-if="type" class="mb-3">
                      <argon-input
                        id="accountId"
                        type="text"
                        placeholder="Account ID"
                        name="accountId"
                        size="lg"
                        v-model="accountId"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        v-model="email"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        v-model="password"
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me">Remember me</argon-switch>
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="dark"
                        fullWidth
                        size="lg">Sign in</argon-button>
                    </div>
                  </form>
                </div>
                <div v-if="!type" class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      to="/signup"
                      class="text-dark text-gradient font-weight-bold"
                    >Sign up</router-link>
                  </p>
                </div>
              </div>
            </div>

            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden gap-3"
                :style="backgroundStyle"
              >
                <span class="mask bg-gradient-dark opacity-6"></span>
                <img
                  :src="logo"
                  class="w-50 position-relative"
                  alt="main_logo"
                />
                <p class="text-white position-relative">
                  Streamlined messaging platform for effortless, meaningful
                  connections. Simplify your communication and amplify your
                  connections with our intuitive interface and powerful
                  features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
