<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/logo.png";
import logoWhite from "@/assets/logo.png";

const store = useStore();
const userAgentNumber = computed(() => store.getters["user/currentUser"]?.agentNumber);
const userRole = computed(() => store.getters["user/currentUser"]?.role);
const userName = computed(() => store.getters["user/currentUser"]?.userName || store.getters["user/currentUser"]?.displayName );
const isRTL = computed(() => store.state.app.isRTL);
const layout = computed(() => store.state.app.layout);
const sidebarType = computed(() => store.state.app.sidebarType);
const darkMode = computed(() => store.state.app.darkMode);
</script>
<template>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-darkgray'}`"
  />

  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl "
    :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'}    
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
    id="sidenav-main"
    style="z-index: 1 !important;"
  >
    <div class="">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="darkMode || sidebarType === 'bg-default' ? logoWhite : logo"
          class="h-100"
          alt="main_logo"
        />
      </router-link>
    </div>

    <div v-if="userRole !== 'ADMIN'" class="flex flex-col m-2 rounded text-white justify-center text-center py-2 px-3" style="background-color: #e90000; display: flex; flex-direction: column;">
      {{ "National Public Affairs" }}
      <span>{{ userAgentNumber }}</span>
      <span>{{ userName}}</span>
      <span>{{ userRole === "AGENT-ADMIN" || userRole === "USER" ? "ADMIN" : userRole?.replace("AGENT-", "") }}</span>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list />
  </aside>
</template>

<style>
.navbar-brand > img {
  max-height: 100% !important;
}

.bg-darkgray {
  background-color: #2a2a2a;
}
</style>
