<script setup>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import DeleteConfirmationModal from "@/components/Modal/index.vue";
import { getFormattedDate } from "@/utils";

const body = document.getElementsByTagName("body")[0];
const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const dncs = computed(() => store.getters["dncData/getDNCs"]);
const loading = computed(() => store.getters["dncData/isLoading"]);
const error = computed(() => store.getters["dncData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const userRole = computed(() => store.getters["user/currentUser"].role);
const sortedData = computed(() => {
  if (!sortColumn.value) return dncs.value;

  return [...dncs.value].sort((a, b) => {
    let aVal = sortColumn.value !== "user" ? a[sortColumn.value] : a.user?.userName ;
    
    let bVal = sortColumn.value !== "user" ? b[sortColumn.value] : b.user?.userName ;

    if (sortColumn.value === 'addedDate') {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }

    if (aVal < bVal) return sortDirection.value === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === 'asc' ? 1 : -1;
    return 0;
  });
});

const dncSearch = ref("");
const selectedIdentity = ref(null);
const selectedDNCLists = ref([]);
const sortColumn = ref('');
const sortDirection = ref('asc');
const currentPage = ref(1);
const itemsPerPage = ref(10);
const itemsPerPageOptions = [5, 10, 25, 50];
const showDeleteModal = ref(false)

const sort = (column) => {
  
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
};

const deleteUser = (id) => {
  showDeleteModal.value = true
  selectedDNCLists.value = [id]
}

const totalPages = computed(() => Math.ceil(sortedData.value.length / itemsPerPage.value));

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  console.log(sortedData.value);
  
  
  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers
  
  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }
  
  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }
  
  // Add ellipsis if necessary
  if (startPage > 1) range.unshift('...');
  if (endPage < totalPageCount) range.push('...');
  
  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);
  
  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  await store.dispatch("dncData/fetchDNCs", { userId: userId.value });
});

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity;
  await store.dispatch("dncData/filterDNCByIdentity", {
    userId: userId.value,
    identityId: identity.identityId,
  });
};

const handleDNCSearch = async () => {
  await store.dispatch("dncData/searchDNCs", {
    userId: userId.value,
    query: dncSearch.value,
  });
};

const onAdd = () => {
  store.commit("dncData/toggleAddDNCPanel");
};

const onDelete = async () => {
  try {
    await store.dispatch("dncData/deleteDNC", selectedDNCLists.value)
    console.log('DNCs deleted successfully')
    // Clear the selection after successful deletion
    selectedDNCLists.value = []
    showDeleteModal.value = false
    // You might want to refresh your DNC list here or emit an event to the parent component
  } catch (error) {
    console.error('Error deleting DNCs:', error)
  }
  await store.dispatch("dncData/fetchDNCs", { userId: userId.value });
  
};

</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">DNC Manager</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <p>
              The Do-Not-Contact database contains records that have opted out of being
              contacted in the future. Records here will be removed from subsequent phone
              list uploads.
            </p>
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="dncSearch" @input="handleDNCSearch" />
              <div class="d-flex align-items-center ms-4">
                Identity:
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedIdentity?.identityName || "Identity" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <!-- <argon-button
                v-if="selectedDNCLists.length > 0"
                color="warning"
                size="md"
                class="border-radius-2xl ms-2"
                @click="onDelete()"
              >
                Delete {{ selectedDNCLists.length }} DNC Lists
              </argon-button> -->
              <argon-button
                v-if="userRole !== 'AGENT-READER' && userRole !== 'AGENT-CLICKER'" 
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd()"
              >
                <i class="fas fa-plus text-xs text-white" aria-hidden="true"></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      @click="sort('value')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Name
                      <span v-if="sortColumn === 'value'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('name')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Value
                      <span v-if="sortColumn === 'name'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('dncType')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Type
                      <span v-if="sortColumn === 'dncType'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('user')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Added By
                      <span v-if="sortColumn === 'user'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('addedDate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Added On
                      <span v-if="sortColumn === 'addedDate'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('reason')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Reason
                      <span v-if="sortColumn === 'reason'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dnc, index) in paginatedData" :key="index">
                    <td class="px-4 text-center">
                      {{ dnc.name }}
                    </td>
                    <td class="px-4 text-center">
                      {{ dnc.value }}
                    </td>
                    <td class="px-4 text-center">
                      {{ dnc.dncType }}
                    </td>
                    <td class="px-4 text-center">
                      {{ dnc.user?.userName }}
                    </td>
                    <td class="px-4 text-center">
                      {{ getFormattedDate(dnc.addedDate) }}
                    </td>
                    <td class="px-4 text-center">
                      {{ dnc.reason }}
                    </td>
                    <td class="px-4 text-center">
                      <i
                        v-if="userRole === 'AGENT-ADMIN' || userRole === 'ADMIN'"
                        class="fas fa-trash-can text-primary text-sm opacity-10" @click.prevent="deleteUser(dnc.id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <DeleteConfirmationModal 
                :isOpen="showDeleteModal"
                content='Do you want to delete this DNC?'
                itemName="User Account"
                @confirm="onDelete"
                @cancel="showDeleteModal = false"
              />
              <div v-if="totalPages > 1" class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5">
                <div>
                  <span class="me-2">Rows per page:</span>
                  <select v-model="itemsPerPage" @change="resetPage">
                    <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="pagination-nav">
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="goToFirstPage"
                    :disabled="currentPage === 1"
                    title="First Page"
                  >
                    &lt;&lt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    title="Previous Page"
                  >
                    &lt;
                  </button>
                  <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
                    <button
                      v-if="pageNum !== '...'"
                      class="btn btn-sm me-1"
                      :class="pageNum === currentPage ? 'btn-primary' : 'btn-secondary'"
                      @click="goToPage(pageNum)"
                    >
                      {{ pageNum }}
                    </button>
                    <span v-else class="mx-1">...</span>
                  </template>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    title="Next Page"
                  >
                    &gt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToLastPage"
                    :disabled="currentPage === totalPages"
                    title="Last Page"
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}
</style>