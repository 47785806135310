// store/modules/mediaData.js
import axios from 'axios';

const state = {
  media: [],
  loading: false,
  error: null,
  selectedIdentity: null,
  assignedMedia: null,
  showAddMediaPanel: false,
  showAssignMediaPanel: false,
};

const mutations = {
  setMedia(state, media) {
    state.media = media;
  },
  setSelectedIdentity(state, identity) {
    state.selectedIdentity = identity;
  },
  setAssignedMedia(state, media) {
    state.assignedMedia = media;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  updateMediaData(state, payload) {
    state.media = payload;
  },
  toggleAddMediaPanel(state) {
    state.showAddMediaPanel = !state.showAddMediaPanel;
  },
  toggleAssignMediaPanel(state) {
    state.showAssignMediaPanel = !state.showAssignMediaPanel;
  },
};

const actions = {
  async fetchMedia({ commit }, { userId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/media?account_id=${userId}`
      );
      commit('setMedia', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error fetching media', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async filterMedia({ commit }, { userId, identityId, query }) {
    commit('setLoading', true);
    try {
      const params = {
        account_id: userId,
        identity_id: identityId,
        query,
      };

      // Filter out undefined values
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, v]) => v !== undefined && !!key)
      );
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/media`, {
        params: filteredParams,
      });
      commit('setMedia', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error filtering media', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteMedia({ commit }, mediaId) {
    commit('setLoading', true);
    try {
      await axios.delete(`${process.env.VUE_APP_API_URL}/media/${mediaId}`);
      commit('setError', null);
    } catch (error) {
      console.error('Error deleting media', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  getMedia: (state) => state.media,
  getIdentity: (state) => state.selectedIdentity,
  getAssignedMedia: (state) => state.assignedMedia,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
