<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  itemName: {
    type: String,
    default: 'this item',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  content: {
    type: String,
  },
})

const emit = defineEmits(['confirm', 'cancel'])

const isVisible = ref(props.isOpen)

watch(() => props.isOpen, (newValue) => {
  isVisible.value = newValue
})

const handleConfirm = () => {
  isVisible.value = false
  setTimeout(() => emit('confirm'), 300)
}

const handleCancel = () => {
  isVisible.value = false
  setTimeout(() => emit('cancel'), 300)
}
</script>

<template>
  <Transition name="modal">
    <div v-if="isVisible" class="modal-overlay" @click.self="handleCancel">
      <div class="modal-container">
        <div class="modal-content">
          <h2 class="modal-title">Confirm Deletion</h2>
          <p class="modal-message">
            {{content}}
          </p>
          <div class="modal-actions">
            <button class="btn btn-secondary" @click="handleCancel">
              Cancel
            </button>
            <button class="btn btn-danger" @click="handleConfirm">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 400px;
  padding: 20px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: none;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.modal-message {
  font-size: 1rem;
  color: #666;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.btn:active {
  transform: scale(0.98);
}

.btn-secondary {
  background-color: #f3f4f6;
  color: #374151;
  border: 1px solid #d1d5db;
}

.btn-secondary:hover {
  background-color: #e5e7eb;
}

.btn-danger {
  background-color: #ef4444;
  color: white;
  border: none;
}

.btn-danger:hover {
  background-color: #dc2626;
}

/* Transition animations */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>