<script setup>
import { onBeforeMount, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import SmsJobsTable from "./SMSReportsTable.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const isRTL = computed(() => store.state.app.isRTL);
const userId = computed(() => store.getters["user/currentUser"].id);
const userRole = computed(() => store.getters["user/currentUser"]?.role);
const identities = computed(() => {
  const data = store.getters["identityData/getIdentities"];
  return [
    { identityId: '', identityName: 'All Identities' }, // New first item
    ...data // Spread the existing identities
  ];
});
const jobs = computed(() => store.getters["jobData/getJobs"]);

const selectedJobLists = ref([]);
const dateRange = ref(null);
const jobSearch = ref("");
const selectedIdentity = ref("");
const selectedStatus = ref("");
const selectedSendType = ref("");
const displayDateRange = computed(() => {
  if (dateRange.value && dateRange.value.length === 2) {
    const [start, end] = dateRange.value;
    return `${formatDate(start)} - ${formatDate(end)}`;
  }
  return 'Select Date Range';
});
const selectedIdentityName = computed(() => {
  if (!identities.value || !selectedIdentity.value) return 'Identity';
  const identity = identities.value.find(itm => itm?.identityId === selectedIdentity.value);
  return identity?.identityName ?? 'Unknown';
});

const filteredJobs = computed(() => {
  return jobs.value.filter(job => {
    const matchesSearch = !jobSearch.value || job.name.toLowerCase().includes(jobSearch.value.toLowerCase());
    const matchesStatus = !selectedStatus.value || job.status.toLowerCase() == selectedStatus.value.toLowerCase();
    const matchesIdentity = !selectedIdentity.value || job.identityId == selectedIdentity.value;
    const matchesSendType = !selectedSendType.value || job.jobType.toLowerCase() == selectedSendType.value.toLowerCase();
    const matchesDateRange = isWithinDateRange(job.startDate, dateRange.value);
    return matchesSearch && matchesStatus && matchesIdentity && matchesSendType && matchesDateRange;
  });
});

const statuses = ref([
  {
    id: "all",
    name: "All",
    value: false,
  },
  {
    id: "completed",
    name: "Completed",
    value: false,
  },
  {
    id: "active",
    name: "Active",
    value: false,
  },
  {
    id: "paused",
    name: "Paused",
    value: false,
  },
  {
    id: "pending",
    name: "Pending",
    value: false,
  },
  {
    id: "incomplete",
    name: "Incomplete",
    value: false,
  },
]);

const sendTypes = ref([
{
    id: 0,
    name: "All",
    value: "",
  },
  {
    id: 1,
    name: "House Send",
    value: "House File",
  },
  {
    id: 2,
    name: "P2P Send",
    value: "P2P",
  },
  {
    id: 3,
    name: "Soapbox P2P Send",
    value: "Soapbox P2P",
  },
]);

const smsJobsHeadings = [
  {field: "Send Date", value: "startDate"},
  {field: "Identity", value: null},
  {field: "Send Type", value: "jobType"},
  {field: "Job Name", value: "name"},
  {field: "Status", value: "status"},
  {field: "Messages Sent", value: "messagesSent"},
  {field: "Messages confirmed", value: null},
  {field: "Total Send Cost", value: "cost"},
  {field: "Action", value: null},
];

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
  filterJobs()
});

onMounted(async () => {
  if (userId.value) {
    await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
  }
});

const handleDateRangeChange = (newDateRange) => {
  dateRange.value = newDateRange;
};

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString();
};

const isWithinDateRange = (date, range) => {
  if (!range || range.length !== 2) return true;
  const [start, end] = range;
  const jobDate = new Date(date);
  return jobDate >= start && jobDate <= end;
};

const filterJobs = async () => {
  const payload = {
    accountId: userId.value,
  };

  if (selectedIdentity.value) {
    payload.identityId = selectedIdentity.value.identityId;
  }

  if (selectedStatus.value) {
    payload.status = selectedStatus.value.name?.toLowerCase();
  }

  if (selectedSendType.value) {
    payload.jobType = selectedSendType.value.value;
  }

  if (jobSearch.value) {
    payload.query = jobSearch.value;
  }

  // resultJobs.value = jobs.value.filter((itm) => itm.name.includes(jobSearch.value))
  // await store.dispatch("jobData/filterJobs", payload);
};

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity.identityId;
  // await filterJobs();
};

const handleJobSearch = async () => {
  await filterJobs();
};

const onStatusChange = async (status) => {
  if(status.name === "All") {
    selectedStatus.value = ""
  } else {
    selectedStatus.value = status.name;
  }
  // await filterJobs();
};

const onSendTypeChange = async (sendType) => {
  console.log(sendType);
  selectedSendType.value = sendType.value;
  // await filterJobs();
};

// const onCreateJobClick = () => {
//   router.push("/select-send-type");
// };

const onDelete = async () => {
  try {
    await store.dispatch("jobData/deleteJob", selectedJobLists.value)
    console.log('DNCs deleted successfully')
    // Clear the selection after successful deletion
    selectedJobLists.value = []
    // You might want to refresh your DNC list here or emit an event to the parent component
  } catch (error) {
    console.error('Error deleting DNCs:', error)
  }
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
  
};

const onJobSelect = (selected, e) => {
  e.preventDefault();
  if (e.target.checked) {
    if (!selectedJobLists.value.includes(selected.jobId)) {
      selectedJobLists.value.push(selected.jobId)
    }
  } else {
    selectedJobLists.value = selectedJobLists.value.filter(
      (id) => id !== selected.jobId
    )
  }
}

const onClickDelete = async (id) => {
  try {
    await store.dispatch("jobData/deleteJob", [id])
    console.log('DNCs deleted successfully')
    // Clear the selection after successful deletion
    selectedJobLists.value = []
    // You might want to refresh your DNC list here or emit an event to the parent component
  } catch (error) {
    console.error('Error deleting DNCs:', error)
  }
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
}
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div class="d-flex mb-4">
              <div
                class="pe-md-3 d-flex align-items-center"
                :class="isRTL ? 'me-md-0' : 'ms-md-0'"
              >
                <search-bar v-model="jobSearch" @input="handleJobSearch" />
              </div>
              <argon-dropdown class="me-1">
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ selectedStatus || "Status" }}
                  </argon-button>
                </template>
                <a
                  v-for="(status, index) in statuses"
                  class="dropdown-item d-flex align-items-center"
                  href="#"
                  :key="index"
                  @click="onStatusChange(status)"
                >
                  <!-- <input type="checkbox" :id="status.id" :name="status.name" :checked="status.value" /> -->
                  <label :for="status.name" class="mb-0">{{ status.name }}</label>
                </a>
              </argon-dropdown>
              <div class="d-flex align-items-center me-3">
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedIdentityName }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <div class="d-flex align-items-center me-3">
              <argon-dropdown>
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ selectedSendType || "Send Type" }}
                  </argon-button>
                </template>
                <a
                  v-for="(sendType, index) in sendTypes"
                  :key="index"
                  class="dropdown-item d-flex align-items-center"
                  href="#"
                  @click="onSendTypeChange(sendType)"
                >
                  <!-- <input type="checkbox" :id="sendType.id" :name="sendType.name" /> -->
                  <label :for="sendType.name" class="mb-0">{{ sendType.name}}</label>
                </a>
              </argon-dropdown>
              </div>
              <argon-dropdown>
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ displayDateRange || "Send Date Range" }}
                  </argon-button>
                </template>
                <VueDatePicker 
                  v-model="dateRange" 
                  :range="{ partialRange: false }" 
                  time-picker-inline
                  @update:modelValue="handleDateRangeChange"
                />
              </argon-dropdown>
              <div class="ms-auto">
                <!-- <argon-button v-if="userRole !== 'AGENT-READER'" color="dark" @click="onCreateJobClick">
                  Create Job +
                </argon-button> -->
                <argon-button
                  v-if="selectedJobLists.length > 0 &&  userRole === 'ADMIN'"
                  color="warning"
                  size="md"
                  class="border-radius-2xl ms-2"
                  @click="onDelete()"
                >
                  Delete {{ selectedJobLists.length }} Job Lists
                </argon-button>
              </div>
            </div>
            <sms-jobs-table :headings="smsJobsHeadings" :data="filteredJobs" :itemClick="onJobSelect" :onClickDelete="onClickDelete"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
