<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import Vue3TagsInput from "vue3-tags-input";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";

const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const phoneList = computed(() => store.getters["phoneListData/getSelectedPhoneList"]);
const suppressionList = computed(
  () => store.getters["phoneListData/getSelectedSuppressionList"]
);
const error = computed(() => store.getters["phonListData/getError"]);
const isRTL = computed(() => store.state.isRTL);

const phoneListData = ref({});
const availableSystemColumns = ref();
const listTypes = ref(["IVR", "RVM", "Bulk SMS", "P2P SMS", "Suppression"]);
const step = ref("Upload Phone List");

const optionalFeatures = computed(() => {
  switch (phoneListData.value.type) {
    case "IVR":
      return ["Split States", "Suppress Cell", "NDNC Scrub"];
    case "RVM":
      return ["Split States", "Add Landlines", "NDNC Scrub", "Scrub Against Florida DNC"];
    case "Bulk SMS":
      return ["Split States", "NDNC Scrub", "Scrub Against Florida DNC"];
    case "P2P SMS":
      return ["Split States", "NDNC Scrub", "Scrub Against Florida DNC"];
    case "Suppression":
      return ["Split States"];
    default:
      return [];
  }
});

const isUploadInvalid = computed(() => {
  return (
    !phoneListData.value.selectedFile ||
    !phoneListData.value.identity ||
    !phoneListData.value.name ||
    !phoneListData.value.type
  );
});

const panelRef = ref(null);

const initialize = () => {
  availableSystemColumns.value = [
    { text: "title" },
    { text: "first_name" },
    { text: "mid_name" },
    { text: "last_name" },
    { text: "address1" },
    { text: "address2" },
    { text: "city" },
    { text: "state" },
    { text: "zip" },
    { text: "email" },
    { text: "lead_phone" },
  ];
};

onBeforeMount(() => {
  initialize();
});

// onMounted(() => {
//   // Bind the click outside event
//   document.addEventListener("click", handleOutsideClick);
// });

// onBeforeUnmount(() => {
//   // Unbind the click outside event
//   document.removeEventListener("click", handleOutsideClick);
// });

// const handleOutsideClick = (event) => {
//   if (panelRef.value && !panelRef.value.contains(event.target)) {
//     toggleAddPhoneListPanel();
//   }
// };

const toggleAddPhoneListPanel = () => store.commit("jobData/toggleAddPhoneListPanel");

const onHandleFileUpload = (event) => {
  // Update selectedFile with the chosen file
  phoneListData.value.selectedFile = event.target.files[0];
};

const onSplitStatesClick = () => {
  //
};

const onSuppressCellClick = () => {
  //
};

const onAddLandlinesClick = () => {
  //
};

const onNDNCScrubClick = () => {
  //
};

const onScrubAgainstFloridaDNCClick = () => {
  //
};

const mapListData = (listData) => {
  phoneListData.value.data = listData;
  phoneListData.value.leadsLoaded = listData.count || 0;
  phoneListData.value.assignedSystemColumns = Array.from(
    { length: phoneListData.value.data.sample[0].length },
    () => Array.from({ length: 0 })
  );
  phoneListData.value.tags = Array.from({
    length: phoneListData.value.data.sample[0].length,
  });
};

const uploadList = async () => {
  /*
  Payload - to be considered later
    file: (binary)
    account: 11530283
    suppress_cell_phones: 6
    identity_id: 11532906
  */
  try {
    const formData = new FormData();
    formData.append("file", phoneListData.value.selectedFile);

    // Make API call to upload the file
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/phone_lists/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      console.log("List file uploaded successfully");
      mapListData(response.data);
    } else {
      console.error("Failed to upload a list file");
    }
  } catch (error) {
    console.error("Error uploading a list file:", error);
  }
};

const handleSelectedTag = (tag, index) => {
  phoneListData.value.assignedSystemColumns[index].push(tag);
  availableSystemColumns.value = availableSystemColumns.value.filter(
    (column) => column.text !== tag.text
  );
};

const handleRemovedTag = (tagIndex, colIndex) => {
  availableSystemColumns.value.push(
    phoneListData.value.assignedSystemColumns[colIndex][tagIndex]
  );
  phoneListData.value.assignedSystemColumns[colIndex].splice(tagIndex, 1);
};

const onContinue = async () => {
  await uploadList();
  step.value = "Phone List Mapping";
};

const onBack = () => {
  step.value = "Upload Phone List";
};

const savePhoneList = async () => {
  /* Payload and response to be considered later
  Payload
    count: 1
    filename: 9334d58f99324472aa6a664e4b5bd254.csv
    identity_id: 11532906
    list_map: {"lead_phone":3,"extern_id":1,"first_name":3,"last_name":1,"suffix":2}
    list_name: Test
    split_states: 0
    suppress_cell_phones: 4
    use_nat_dnc: 0
    use_state_dnc: 0
    account: 11530283
    ver: 1.3
    opt_in: true

  Response
    account_id:"11530283"
    identity_id:"11532906"
    integration_id:null
    is_mapped_list:1
    list_name:"Test"
    list_state:"PENDING"
    pending_list_id:2782597
    split_states:0
    split_timezones:1
    suppress_cell_phones:4
    token:"a50d7e1109fc707978e4af44d623c86d"
    uploaded_by:"Grant Bollinger"
    uploaded_date:"2024-05-07T02:49:55.250Z"
    use_nat_dnc:0
    use_state_dnc:0
  */

  let dataColumns = [];
  let systemColumns = [];

  if (
    phoneListData.value.data &&
    phoneListData.value.data.sample &&
    phoneListData.value.data.sample[0]
  ) {
    dataColumns = phoneListData.value.data.sample[0];
  }
  systemColumns = phoneListData.value.assignedSystemColumns;

  const columnMap = dataColumns?.map((dataColumn, index) => ({
    dataColumn,
    systemColumns: systemColumns[index].map((sysColumn) => sysColumn.text),
  }));

  const payload = {
    accountId: userId.value,
    listName: phoneListData.value.name,
    listType: phoneListData.value.type,
    identityId: phoneListData.value.identity.identityId,
    fileUrl: phoneListData.value.data.fileUrl,
    columnMap,
  };

  if (phoneListData.value.type === "Suppression") {
    payload.leadsLoaded = phoneListData.value.leadsLoaded;
  } else {
    payload.leadsRemaining = phoneListData.value.leadsRemaining;
  }

  await store.dispatch("phoneListData/addPhoneList", payload);
  toggleAddPhoneListPanel();
  if (!error.value) {
    if (phoneListData.value.type === "Suppression") {
      await store.dispatch("phoneListData/fetchPhoneLists", { userId: userId.value });
      await store.dispatch("phoneListData/fetchSuppressionLists", {
        userId: userId.value,
      });
      store.commit("jobData/addSuppressionListStepData", suppressionList.value);
    } else {
      await store.dispatch("phoneListData/fetchPhoneLists", { userId: userId.value });
      store.commit("jobData/addPhoneListStepData", phoneList.value);
    }
  }
  phoneListData.value = {};
  initialize();
  step.value = "Upload Phone List";
};
</script>

<template>
  <div ref="panelRef" class="add-phone-list fixed-plugin">
    <div class="shadow-lg card overflow-scroll p-4">
      <div class="pb-0">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="">Phone List Manager</h5>
        </div>
        <div
          class="mt-1"
          @click="toggleAddPhoneListPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div>
        <div
          v-if="step === 'Upload Phone List'"
          class="d-flex align-items-center justify-content-end"
        >
          <argon-button
            class="border-radius-2xl"
            variant="gradient"
            color="dark"
            size="md"
            :disabled="isUploadInvalid"
            @click="onContinue"
          >
            Save
          </argon-button>
        </div>
        <div v-else class="d-flex align-items-center justify-content-end">
          <argon-button
            class="border-radius-2xl me-2"
            variant="gradient"
            color="light"
            size="md"
            @click="onBack"
          >
            Back
          </argon-button>
          <argon-button
            class="border-radius-2xl"
            variant="gradient"
            color="dark"
            size="md"
            @click="savePhoneList"
          >
            Finish
          </argon-button>
        </div>
        <div v-if="step === 'Upload Phone List'">
          <h5 class="mb-3">Upload Phone List</h5>
          <p class="text-danger text-sm">
            Reminder: There will be a list scrubbing charge of $0.0025 per Contact Loaded,
            billed the first time you assign this list to a job. Invalid, Malformed and
            Duplicated records will not be billed.
          </p>
          <div class="">
            <label for="upload-file-input" class="form-control-label">Upload File:</label>
            <div class="row">
              <div class="col-12">
                <argon-input
                  type="file"
                  @change="onHandleFileUpload"
                  accept=".txt, .csv, .zip"
                />
                <p class="text-xs">
                  The list must contain a single text (.txt) or comma-separated value
                  (.csv) file with one entry per line. This file may be zipped (.zip) if
                  needed.
                </p>
              </div>
            </div>
          </div>
          <h5 class="mb-3">Phone List Options</h5>
          <div class="row mb-3">
            <div class="col-lg-6 col-12">
              <div class="mb-3">
                <label for="email-input" class="form-control-label">Identity:</label>
                <argon-dropdown class="">
                  <template v-slot:title>
                    <argon-button color="light" class="w-100 dropdown-toggle">
                      {{ phoneListData.identity?.identityName || "Identity" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, iIndex) in identities"
                    :key="iIndex"
                    class="dropdown-item d-flex align-items-center"
                    @click="phoneListData.identity = identity"
                  >
                    <label class="mb-0">{{ identity.displayName || identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <div class="mb-3">
                <label for="email-input" class="form-control-label">List Name:</label>
                <argon-input
                  id="email"
                  type="text"
                  name="email"
                  size="md"
                  v-model="phoneListData.name"
                />
              </div>
              <div class="mb-3">
                <label for="email-input" class="form-control-label">
                  What is the list type you are loading?:
                </label>
                <argon-dropdown class="">
                  <template v-slot:title>
                    <argon-button color="light" class="w-100 dropdown-toggle">
                      {{ phoneListData.type || "Select List Type" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(item, index) in listTypes"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="phoneListData.type = item"
                  >
                    <label class="mb-0">{{ item }}</label>
                  </a>
                </argon-dropdown>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <h6>Optional Features</h6>
              <div class="d-flex justify-content-between align-items-center">
                <div
                  v-if="optionalFeatures.includes('Split States')"
                  class="d-block mb-4"
                >
                  <label class="mb-0">Split States:</label>
                  <div class="form-check form-switch my-auto">
                    <input
                      class="mt-1 form-check-input"
                      type="checkbox"
                      id="splitStates"
                      :checked="phoneListData.splitStates"
                      @click="onSplitStatesClick"
                    />
                  </div>
                </div>
                <div
                  v-if="optionalFeatures.includes('Suppress Cell')"
                  class="d-block mb-4"
                >
                  <label class="mb-0">Suppress Cell:</label>
                  <div class="form-check form-switch my-auto">
                    <input
                      class="mt-1 form-check-input"
                      type="checkbox"
                      id="addLandlines"
                      :checked="phoneListData.suppressCell"
                      @click="onSuppressCellClick"
                    />
                  </div>
                </div>
                <div
                  v-if="optionalFeatures.includes('Add Landlines')"
                  class="d-block mb-4"
                >
                  <label class="mb-0">Add Landlines:</label>
                  <div class="form-check form-switch my-auto">
                    <input
                      class="mt-1 form-check-input"
                      type="checkbox"
                      id="addLandlines"
                      :checked="phoneListData.addLandlines"
                      @click="onAddLandlinesClick"
                    />
                  </div>
                </div>
                <div v-if="optionalFeatures.includes('NDNC Scrub')" class="d-block mb-4">
                  <label class="mb-0">NDNC Scrub:</label>
                  <div class="form-check form-switch my-auto">
                    <input
                      class="mt-1 form-check-input"
                      type="checkbox"
                      id="ndncScrub"
                      :checked="phoneListData.ndncScrub"
                      @click="onNDNCScrubClick"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="optionalFeatures.includes('Scrub Against Florida DNC')"
                class="d-block mb-4"
              >
                <label class="mb-0">Scrub Against Florida DNC:</label>
                <div class="form-check form-switch my-auto">
                  <input
                    class="mt-1 form-check-input"
                    type="checkbox"
                    id="scrubAgainstFloridaDNC"
                    :checked="phoneListData.scrubAgainstFlordiaDNC"
                    @click="onScrubAgainstFloridaDNCClick"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h5 class="mb-3">Phone List Mapping</h5>
          <p class="text-primary text-sm">
            Map the "Available Phone List Columns" below either by clicking and dragging
            from "Available System Columns" at the top or by typing and completing the
            related search box in the "Assigned System Columns" column.
          </p>
          <h6 class="">Available System Columns</h6>
          <div class="">
            <argon-button
              v-for="(column, index) in availableSystemColumns"
              :key="index"
              color="primary"
              class="px-2 py-1 me-2 mb-2 border-radius-2xl"
            >
              {{ column.text }}
            </argon-button>
          </div>
          <h6 class="">Available Phone List Columns</h6>
          <div class="">
            <div class="table-responsive p-0">
              <table class="table align-items0center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Column
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Column Preview:
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Assigned System Columns
                    </th>
                  </tr>
                </thead>
                <tbody v-if="phoneListData.data">
                  <tr
                    v-for="(column, index) in phoneListData.data.sample[0]"
                    :key="index"
                  >
                    <td class="px-4">{{ `Col ${index + 1}` }}</td>
                    <td class="px-4">{{ column }}</td>
                    <td class="px-4">
                      <vue3-tags-input
                        v-model:tags="phoneListData.assignedSystemColumns[index]"
                        v-model="phoneListData.tags[index]"
                        :select="true"
                        :select-items="availableSystemColumns"
                        @on-select="(tag) => handleSelectedTag(tag, index)"
                        @on-remove="(tag) => handleRemovedTag(tag, index)"
                        placeholder="Select the tag"
                      >
                        <template #item="{ tag }">
                          {{ tag.text }}
                        </template>
                        <template #no-data> No Data </template>
                        <template #select-item="tag">
                          {{ tag.text }}
                        </template>
                      </vue3-tags-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.add-phone-list.fixed-plugin .card {
  width: 640px !important;
  right: -640px !important;
}
.add-phone-list.fixed-plugin.show .card {
  right: 0 !important;
}
</style>
