<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getFormattedDate } from "@/utils";

const store = useStore();
const router = useRouter();

const userId = computed(() => store.getters["user/currentUser"].id);
const jobs = computed(() => store.getters["jobData/getJobs"]);
const identities = computed(() => store.getters["identityData/getIdentities"]);

const sortColumn = ref('');
const sortDirection = ref('asc');
const currentPage = ref(1);
const itemsPerPage = ref(10);
const itemsPerPageOptions = [5, 10, 25, 50];

const sort = (column) => {
  
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
};

const totalPages = computed(() => Math.ceil(sortedData.value.length / itemsPerPage.value));

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const sortedData = computed(() => {
  if (!sortColumn.value) return jobs.value;

  return [...jobs.value].sort((a, b) => {
    let aVal = a[sortColumn.value];
    
    let bVal = b[sortColumn.value];

    if (sortColumn.value === 'startDate') {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }

    if (sortColumn.value === "cost") {
      aVal = (parseInt(a.messagesSent) + parseInt(a.messagesOptOut) + parseInt(a.messagesNonOptOut)) * 0.01;
      bVal = (parseInt(b.messagesSent) + parseInt(b.messagesOptOut) + parseInt(b.messagesNonOptOut)) * 0.01;
    }

    if (aVal < bVal) return sortDirection.value === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === 'asc' ? 1 : -1;
    return 0;
  });
});

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers
  
  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }
  
  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }
  
  // Add ellipsis if necessary
  if (startPage > 1) range.unshift('...');
  if (endPage < totalPageCount) range.push('...');
  
  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);
  
  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

const jobsHeadings = [
  {field: "Identity", value: null},
  {field: "Job Name", value: "name"},
  {field: "Send Date", value: "startDate"},
  {field: "Messages Sent", value: "messagesSent"},
  {field: "Action", value: null},
];

const getIdentityById = (id) => {
  return identities.value.find((itm) => itm.identityId == id) || null;
};

onMounted(async () => {
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
});

const onViewDetails = (jobId) => {
  router.push(`/chats/${jobId}`);
};
</script>

<template>
  <div class="container-fluid py-4">
    <h2 class="text-white mb-4">Chats</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div class="card min-vh-50">
              <div class="card-header pb-0">
                <h6>Job List</h6>
              </div>
              <div class="card-body px-0 pt-0 pb-2">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th
                        v-for="(heading, index) in jobsHeadings "
                        :key="index"
                        @click="sort(heading.value)"
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                      >
                        {{ heading.field }}
                        <span v-if="sortColumn === heading.value && heading.value !== null">
                          {{ sortDirection === 'asc' ? '▲' : '▼' }}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in paginatedData" :key="index">
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0 text-center">
                          {{ getIdentityById(item.identityId)?.identityName }}
                        </p>
                      </td>
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0 text-center">
                          {{ item.name }}
                        </p>
                      </td>
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0 text-center">
                          {{ getFormattedDate(item.startDate) || 0 }}
                        </p>
                      </td>
                      <td class="px-4">
                        <p class="text-xs font-weight-bold mb-0 text-center">
                          {{ item.messagesSent || 0 }}
                        </p>
                      </td>
                      <td class="px-4 text-center">
                        <i
                          class="fas fa-eye text-primary text-sm opacity-10"
                          @click.prevent="onViewDetails(item.jobId)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="totalPages > 1" class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5">
          <div>
            <span class="me-2">Rows per page:</span>
            <select v-model="itemsPerPage" @change="resetPage">
              <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="pagination-nav">
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="goToFirstPage"
              :disabled="currentPage === 1"
              title="First Page"
            >
              &lt;&lt;
            </button>
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="prevPage"
              :disabled="currentPage === 1"
              title="Previous Page"
            >
              &lt;
            </button>
            <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
              <button
                v-if="pageNum !== '...'"
                class="btn btn-sm me-1"
                :class="pageNum === currentPage ? 'btn-primary' : 'btn-secondary'"
                @click="goToPage(pageNum)"
              >
                {{ pageNum }}
              </button>
              <span v-else class="mx-1">...</span>
            </template>
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="nextPage"
              :disabled="currentPage === totalPages"
              title="Next Page"
            >
              &gt;
            </button>
            <button
              class="btn btn-sm btn-secondary"
              @click="goToLastPage"
              :disabled="currentPage === totalPages"
              title="Last Page"
            >
              &gt;&gt;
            </button>
          </div>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}
</style>