<script setup>
import { onBeforeMount, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import PreviewMediaModal from "./PreviewMediaModal.vue";
import DeleteConfirmationModal from "@/components/Modal/index.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const identities = computed(() => {
  const data = store.getters["identityData/getIdentities"];
  return [
    { identityId: '', identityName: 'All Identities' }, // New first item
    ...data // Spread the existing identities
  ];
});
const mediaData = computed(() => store.getters["mediaData/getMedia"]);
const loading = computed(() => store.getters["mediaData/isLoading"]);
const error = computed(() => store.getters["mediaData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const userRole = computed(() => store.getters["user/currentUser"].role);
const filteredMedia = computed(() => {
  return mediaData.value.filter(media => {
    const matchesSearch = !mediaSearch.value || media.title.toLowerCase().includes(mediaSearch.value.toLowerCase());
    const matchesIdentity = !selectedIdentity.value || media.identityId == selectedIdentity.value;
    return matchesSearch && matchesIdentity;
  });
});
const selectedIdentityName = computed(() => {
  if (!identities.value || !selectedIdentity.value) return 'Identity';
  const identity = identities.value.find(itm => itm?.identityId === selectedIdentity.value);
  return identity?.identityName ?? 'Unknown';
});

const mediaSearch = ref("");
const selectedIdentity = ref("");
const showModal = ref(false);
const selectedMedia = ref({});
const showDeleteModal = ref(false)

const deleteMedia = (media) => {
  showDeleteModal.value = true
  selectedMedia.value = media
}

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  await store.dispatch("mediaData/fetchMedia", { userId: userId.value });
});

const getIdentityName = (identityId) => {
  const found = identities.value?.find((item) => item.identityId == identityId);
  return found?.identityName;
};

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity.identityId;
  // await store.dispatch("mediaData/filterMedia", {
  //   userId: userId.value,
  //   identityId: identity.identityId,
  //   query: mediaSearch.value,
  // });
};

// const handleMediaSearch = async () => {
//   await store.dispatch("mediaData/filterMedia", {
//     userId: userId.value,
//     query: mediaSearch.value,
//     identityId: selectedIdentity.value.identityId,
//   });
// };

const onUpload = () => {
  store.commit("mediaData/toggleAddMediaPanel");
};

const onRefresh = () => {
  location.reload();
};

const onMediaClick = (media) => {
  selectedMedia.value = media;
  if (showModal.value === false) showModal.value = true;
};

const onRemove = async () => {
  await store.dispatch("mediaData/deleteMedia", selectedMedia.value.mediaId);
  await store.dispatch("mediaData/fetchMedia", { userId: userId.value });
  showDeleteModal.value = false
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">Media Manager</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <h3>Media Files</h3>
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="mediaSearch" />
              <argon-dropdown class="ms-3">
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ selectedIdentityName }}
                  </argon-button>
                </template>
                <a
                  v-for="(identity, index) in identities"
                  :key="index"
                  class="dropdown-item d-flex align-items-center"
                  @click="onIdentitySelect(identity)"
                >
                  <label class="mb-0">{{ identity.identityName }}</label>
                </a>
              </argon-dropdown>
              <argon-button
                v-if="userRole !== 'AGENT-READER' && userRole !== 'AGENT-CLICKER'" 
                color="primary"
                class="border-radius-2xl ms-auto"
                @click="onUpload"
                >Upload</argon-button
              >
              <argon-button
                v-if="userRole !== 'AGENT-READER' && userRole !== 'AGENT-CLICKER'" 
                color="success"
                class="border-radius-2xl ms-2"
                @click="onRefresh"
                >Refresh</argon-button
              >
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Preview
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <div class="d-flex align-items-center">
                        <span class="me-2">Title</span>/<span class="ms-2">Identity</span>
                      </div>
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Created Date
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(media, index) in filteredMedia" :key="index">
                    <td class="w-15 px-4">
                      <img
                        :src="media.thumbnailUrl"
                        class="media-image me-3"
                        alt="preview"
                        @click.prevent="onMediaClick(media)"
                      />
                    </td>
                    <td class="w-40 px-4">
                      <div
                        class="d-flex flex-column justify-content-center align-items-start"
                      >
                        <strong>{{ media.title }}</strong>
                        <span>{{ getIdentityName(media.identityId) }}</span>
                      </div>
                    </td>
                    <td class="w-20 px-4">
                      <div
                        class="d-flex flex-column justify-content-center align-items-start"
                      >
                        <span>{{ media.createdDate }}</span>
                        <span>Size: {{ media.sizeBytes / 1000 }} KB</span>
                      </div>
                    </td>
                    <td class="px-4">
                      <div v-if="userRole==='AGENT-ADMIN' || userRole === 'ADMIN'" class="d-flex justify-content-end align-items-center">
                        <argon-button
                          color="warning"
                          size="sm"
                          class="action-btn rounded-circle ms-auto px-2"
                          @click="deleteMedia(media)"
                        >
                          <i
                            class="fas fa-trash-alt text-xs text-white"
                            aria-hidden="true"
                          ></i>
                        </argon-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <DeleteConfirmationModal 
                :isOpen="showDeleteModal"
                content='Do you want to delete this Media?'
                itemName="Media"
                @confirm="onRemove"
                @cancel="showDeleteModal = false"
              />
              <preview-media-modal
                :show="showModal"
                :mediaType="selectedMedia.mediaType"
                :mediaSrc="selectedMedia.previewUrl"
                @close="showModal = false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.media-image {
  width: 100px;
}
</style>
